<template>
  <main class="mr-4 md:mr-0 mb-8">
    <trac-loading v-if="isLoading" />

    <trac-validation-observer
      slim
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        @submit.prevent="handleSubmit(store._id ? updateStore : createStore)"
        class="md:ml-8"
      >
        <trac-back-button>Stores</trac-back-button>
        <div class="flex justify-between items-center">
          <ul class="flex-col mt-12 mb-8">
            <li>Stores</li>
            <li class="font-bold mt-4 text-xl">Add store</li>
          </ul>
          <trac-button buttonType="submit">{{
            store._id ? "Update Store" : "Add Store"
          }}</trac-button>
        </div>

        <div class="flex flex-wrap gap-10">
          <div class="custom-width p-8 big-shadow rounded-2xl">
            <h3 class="mb-4">Store Info</h3>
            <div class="mt-5 w-full">
              <trac-validation-provider
                name="store name"
                rules="required"
                v-slot="{ errors }"
              >
                <!-- :disabled="store.name.toLowerCase() === 'main'" -->
                <!-- :disabled="store._id" -->
                <trac-input
                  v-model="store.name"
                  placeholder="Store Name"
                  data-test="store-name"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
            <div class="mt-5 w-full">
              <trac-validation-provider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <trac-input
                  v-model="store.email"
                  placeholder="Email Address"
                  data-test="store-email"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
            <div class="mt-5 w-full">
              <trac-validation-provider
                name="phone number"
                rules="required|positive"
                v-slot="{ errors }"
              >
                <trac-input
                  v-model="store.phone"
                  placeholder="Phone Number"
                  data-test="store-phone"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
          </div>

          <div class="custom-width p-8 big-shadow rounded-2xl">
            <h3 class="mb-4">Store Address</h3>
            <div class="mt-5 w-full">
              <trac-input
                v-model="store.address1"
                placeholder="Enter Address"
                data-test="store-address1"
              ></trac-input>
            </div>

            <div>
              <div
                data-test="store-state"
                @click.stop="stateDropdown = !stateDropdown"
                class="border py-3 leading-5 mt-4 text-gray-500 pl-5 w-full text-xs rounded input-area"
              >
                {{ store.state || "Select State" }}
              </div>
              <div
                v-click-outside="closeState"
                v-if="stateDropdown"
                style="max-height: 19rem"
                class="h-auto max-w-sm w-full z-10 overflow-y-auto absolute bg-white mt-1 shadow border p-2 rounded-md"
              >
                <input
                  required
                  type="text"
                  v-model="stateSearch"
                  class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                />
                <div
                  class="my-2 z-50 text-primaryGray"
                  v-for="(state, index) in formatStates"
                  :key="index"
                >
                  <div class="capitalize" @click="updateState(state)">
                    {{ state.name }}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                :class="{
                  ' pointer-events-none': local_governments.length == 0,
                }"
                @click.stop="lgDropdown = !lgDropdown"
                class="border py-3 leading-5 mt-4 text-gray-500 pl-5 w-full text-xs rounded input-area"
                data-test="dropdown-trigger"
              >
                {{ store.town || "Select Local Government" }}
              </div>
              <div
                v-click-outside="closeLg"
                v-if="lgDropdown"
                style="max-height: 19rem"
                class="h-auto max-w-sm z-10 w-full overflow-y-auto absolute bg-white mt-1 shadow border p-2 rounded-md"
                data-test="dropdown-list"
              >
                <input
                  required
                  type="text"
                  v-model="lgSearch"
                  class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                  data-test="dropdown-input"
                />
                <div class="mt-2" v-for="lg in formatLg" :key="lg">
                  <div
                    class="capitalize text-sm"
                    @click="updateLg(lg)"
                    data-test="dropdown-option"
                  >
                    {{ lg }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="settlementType === 'sameday' && sameday_type === 'multistore'"
            class="custom-width p-8 big-shadow rounded-2xl"
          >
            <h3 class="mb-4">Settlement Account</h3>
            <div class="mt-5 w-1/2">
              <trac-validation-provider
                name="account number"
                rules="required|positive"
                v-slot="{ errors }"
              >
                <trac-input
                  v-model="store.bank_account.account_number"
                  @blur="handleAccountInput"
                  placeholder="Account Number"
                  data-test="account-number"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
            <!-- Bank Name Display (once available) -->
            <div
              v-if="store.bank_account.bank_name"
              class="mt-1 ml- text-primaryBlue"
            >
              <label class="text-xs">{{ store.bank_account.bank_name }}</label>
            </div>

            <div class="mb-1 mt-5 w-96 z-90">
              <div>
                <div
                  @click.stop="bankDropdown = !bankDropdown"
                  class="border py-3 leading-5 mt-4 text-primaryGray pl-5 w-full text-xs rounded input-area"
                  data-test="bank-dropdown-trigger"
                >
                  {{ bankname || "Select Bank" }}
                </div>
                <div
                  v-click-outside="close"
                  v-if="bankDropdown"
                  style="max-height: 19rem"
                  class="h-auto max-w-sm w-full z-50 overflow-y-auto absolute bg-white mt-1 shadow border p-2 rounded-md"
                  data-test="bank-dropdown-list"
                >
                  <input
                    required
                    type="text"
                    v-model="bankSearch"
                    data-test="store-bank"
                    class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                  />
                  <div
                    class="my-2 z-50 text-primaryGray"
                    v-for="(bank, i) in formatBanks"
                    :key="i"
                  >
                    <div
                      class="capitalize cursor-pointer hover:bg-gray-200 text-sm"
                      @click="updateBankCode(bank)"
                      data-test="bank-dropdown-option"
                    >
                      <p class="py-2 pl-3">
                        {{ bank.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Account Name Display (after verification) -->
            <div
              v-if="store.bank_account.account_name"
              class="mt-1 ml- text-primaryBlue"
            >
              <label class="text-xs">{{
                store.bank_account.account_name
              }}</label>
            </div>
            <!-- Error Message Display (if verification fails) -->
            <div v-if="verificationMessage" class="mt-5 text-red-500">
              <label>{{ verificationMessage }}</label>
            </div>
          </div>
        </div>
      </form>
    </trac-validation-observer>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import ClickOutside from "vue-click-outside";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      isLoading: false,
      store: {
        address1: "",
        town: "",
        business_id: GET_USER_BUSINESS_ID(),
        business_name: GET_USER_BUSINESS_DATA().name,
        email: "",
        name: "",
        phone: "",
        register: false,
        state: "",
        bank_account: {
          account_number: "",
          account_name: "",
          bank_name: " ",
          bank_code: "",
        },
      },
      verificationMessage: "",
      settlementType: "",
      sameday_type: "",
      bankDropdown: false,
      bankSearch: "",
      banks: [],
      name: "",
      bankname: "",
      states: [],
      stateSearch: "",
      stateDropdown: false,
      local_governments: [],
      lgDropdown: false,
      lgSearch: "",
    };
  },
  async created() {
    this.isLoading = true;

    const res = await this.$store.dispatch("FETCH_SETTLEMENT_TYPE");

    this.settlementType = res?.data?.settlement_type || "";
    this.sameday_type =
      res?.data?.same_day_preference?.settlement_preference?.name || "";

    if (GET_LOCAL_DB_DATA("store-edit-details")) {
      this.store = GET_LOCAL_DB_DATA("store-edit-details");
      this.store.bank_account = this.store.bank_account || {
        account_number: "",
        account_name: "",
        bank_code: "",
        bank_name: "",
      };
    }
    const locations = await this.$store.dispatch("GET_AVAILABLE_LOCATIONS");
    this.states = locations?.data || "";
    this.isLoading = false;
  },
  directives: {
    ClickOutside,
  },
  computed: {
    formatBanks() {
      let banksTotal = this.banks.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      return banksTotal.filter((bank) => {
        return bank.name.toLowerCase().includes(this.bankSearch.toLowerCase());
      });
    },
    formatStates() {
      return this.states
        .filter((state) => {
          return state.name
            .toLowerCase()
            .includes(this.stateSearch.toLowerCase());
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    formatLg() {
      return this.local_governments
        .filter((state) => {
          return state.toLowerCase().includes(this.lgSearch.toLowerCase());
        })
        .sort((a, b) => a.localeCompare(b));
    },
  },
  methods: {
    async createStore() {
      this.isLoading = true;
      await this.$store.dispatch("CREATE_STORE", this.store);
      const res = this.$store.getters["GET_STORE_RES"];

      this.$store.commit("STORE_ACTION_RES", null);

      if (res.status) {
        // Update local store
        const user = GET_USER_DATA() || {};
        const token = GET_USER_TOKEN();
        user.stores = user.stores || [];

        user.stores.push({
          id: res.data._id,
          name: res.data.name,
          register: res.data.register,
          bank_account: {
            bank_code: res.data.bank_account.bank_code,
            account_number: res.data.bank_account.account_number,
            account_name: res.data.bank_account.account_name,
          },
        });

        SAVE_LOCAL_DB_DATA("traction-app-user-data", { token, user });
        if (GET_LOCAL_DB_DATA("store-edit-details")) {
        } else {
          // Add newly created store to products store stocks
          await this.$store.dispatch("FETCH_ALL_PRODUCTS");
          const productsRes = this.$store.getters["GET_ALL_PRODUCT"];

          if (productsRes.status) {
            (productsRes.items || []).forEach((product) => {
              if (product.variation) {
                (product.varieties || []).forEach((variant) => {
                  variant.store_stock.push({
                    re_order: 0,
                    stock: 0,
                    store_id: res.data._id,
                    store_name: res.data.name,
                  });
                });
              } else {
                (product.store_stock || []).push({
                  re_order: 0,
                  stock: 0,
                  store_id: res.data._id,
                  store_name: res.data.name,
                });
              }
            });
          }
        }

        eventBus.$emit("trac-alert", { message: res.message });
        this.requestSync("stores");
        console.log("Router:", this.$router); // This should log the mock router
        console.log("Router Back:", this.$router.back); // This should log the back function

        this.$router.back();
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.resetForm();
      this.isLoading = false;
        console.log("DID WE MAKE IT HERE?");
    },
    resetForm() {
    this.store = {
      name: '',
      email: '',
      phone: '',
      address1: '',
      bank_account: {
        account_number: '',
        bank_code: '',
        account_name: ''
      }
    };
    this.settlementType = '';
    this.sameday_type = '';
  },
    async updateStore() {
      this.isLoading = true;

      await this.$store.dispatch("UPDATE_STORE", this.store);
      const res = this.$store.getters["GET_STORE_RES"];
      console.log("RES", res);

      this.$store.commit("STORE_ACTION_RES", null);

      if (res.status) {
        // Update local store
        const user = GET_USER_DATA() || {};
        const token = GET_USER_TOKEN();
        user.stores = user.stores || [];

        // user.stores.push({ id: res.data._id, name: res.data.name, register: res.data.register });
        user.stores = user.stores.map((store) => {
          if (store.id === this.store._id) {
            return {
              name: res.data.name,
              id: res.data._id,
              bank_account: {
                bank_code: res.data.bank_account.bank_code,
                account_number: res.data.bank_account.account_number,
                account_name: res.data.bank_account.account_name,
              },
            };
          }
          console.log("USER STORE", user.stores);
          console.log("PARTICULAR STORE", store);
          return store;
        });

        SAVE_LOCAL_DB_DATA("traction-app-user-data", { token, user });

        this.requestSync("stores");
        this.$router.back();
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async fetchAllBankAccounts() {
      await this.$store.dispatch("FETCH_ALL_BANK_ACCOUNTS");
      const res = this.$store.getters["GET_ALL_BANK_ACCOUNTS"];

      if (res.status) {
        this.settlementAccounts = (res.data || [])[0];
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    updateBankCode(x) {
      console.log("X", x);
      this.store.bank_account.bank_code = x.bank_code;
      this.bankname = x.name;
      this.store.bank_account.bank_name = x.name;

      this.verifyBankDetails();
      this.close();
    },
    handleAccountInput() {
      this.verifyBankDetails();
    },
    verifyBankDetails() {
      if (
        this.store.bank_account.bank_code &&
        this.store.bank_account.account_number
      ) {
        this.verifyBank();
      }
    },
    async verifyBank() {
      this.verificationMessage = "";
      let res = await this.$store.dispatch("VERIFY_BANK_DETAILS", this.store);
      if (res.status) {
        const acct_data = res.data.items;
        this.store.bank_account.account_name = acct_data.AccountName;
        this.verificationMessage = "";
      } else {
        this.store.bank_account.account_name = "";
        this.verificationMessage = "Verification failed. Please check details.";
      }
    },
    updateState(state) {
      this.store.state = state.name;
      this.local_governments = state.local_governments;
      console.log("LOCAL GOVTS", this.local_governments);
      this.stateDropdown = false;
      this.stateSearch = "";
    },
    updateLg(lg) {
      this.store.town = lg;
      this.lgDropdown = false;
      this.lgSearch = "";
    },
    close() {
      this.bankDropdown = false;
      this.bankSearch = "";
    },
    closeState() {
      this.stateDropdown = false;
      this.stateSearch = "";
    },
    closeLg() {
      this.lgDropdown = false;
      this.lgSearch = "";
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchAllBankAccounts();

    let res = await this.$store.dispatch("BANK_LIST");
    this.banks = res?.data?.items || "";
    this.name = GET_USER_BUSINESS_DATA().name;
    this.isLoading = false;
  },
};
</script>

<style>
.custom-width {
  width: 22%;
}

@media (min-width: 768px) {
  .custom-width {
    width: 48%;
  }
}
</style>
